<template>
  <div>
    <Drawer
      :mask-closable="false"
      :title="id > 0 ? '详情' : '新增'"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer cash-collection-plan-drawer"
      v-model="flag"
      width="400"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="bk-drawer-body">
        <Form :label-width="140" :model="formd" :rules="rules" @submit.native.prevent ref="form">
          <FormItem label="状态：" prop="Status" required>
            <Select v-if="ABLE" v-model="formd.Status">
              <Option
                :key="i"
                :value="a.key"
                v-for="(a, i) in $designConst.cashCollectionPlan.StatusLst"
              >{{ a.name }}</Option>
            </Select>
            <div v-else>{{$designConst.cashCollectionPlan.Status[formd.Status]}}</div>
          </FormItem>
          <FormItem label="委托单：" prop="OrderId" required>
            <!-- <Button
              @click="fastPickOrder"
              ghost
              size="small"
              style="margin-bottom: 8px"
              type="primary"
              v-if="ABLE && $store.state.order.Id"
            >快速选择：{{ $store.state.order.Code }}</Button> -->
            <Input :value="formd.OrderCode" @on-clear="unPickOrder" clearable readonly v-if="ABLE">
              <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
            </Input>
            <div v-else>{{formd.OrderCode}}</div>
          </FormItem>
          <FormItem label="我方市场负责人：" prop="MarketerId" required>
            <Input
              :value="formd.MarketerName"
              @on-clear="unPickUser"
              clearable
              readonly
              v-if="ABLE"
            >
              <Button @click="toPickUser" icon="ios-more" slot="append"></Button>
            </Input>
            <div v-else>{{formd.MarketerName}}</div>
          </FormItem>
          <FormItem label="收款截止时间：" prop="Deadline" required>
            <DatePicker v-if="ABLE" v-model="formd.Deadline"></DatePicker>
            <div v-else>{{formd.Deadline}}</div>
          </FormItem>
          <FormItem label="实际收款时间：">
            <DatePicker
              format="yyyy-MM-dd HH:mm"
              type="datetime"
              v-if="ABLE"
              v-model="formd.RevTime"
            ></DatePicker>
            <div v-else>{{formd.RevTime}}</div>
          </FormItem>
          <FormItem label="计划提醒时间：">
            <DatePicker v-if="ABLE" v-model="formd.NoticeTime"></DatePicker>
            <div v-else>{{formd.NoticeTime}}</div>
          </FormItem>
          <FormItem label="回款说明：">
            <Input placeholder="将展示给客户" type="textarea" v-if="ABLE" v-model="formd.Description" />
            <div v-else>{{formd.Description}}</div>
          </FormItem>
          <FormItem label="内部备注：">
            <Input type="textarea" v-if="ABLE" v-model="formd.Remark" />
            <div v-else>{{formd.Remark}}</div>
          </FormItem>
        </Form>
      </div>
      <div class="bk-drawer-footer" v-if="ABLE">
        <Button :loading="posting" @click="ok" type="primary">确定</Button>
      </div>
    </Drawer>
    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
    <pick-user @on-ok="onPickUser" ref="PickUser" type="1" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
import PickUser from "../../components/PickUser.vue";
export default {
  components: { PickOrder, PickUser },
  data() {
    return {
      ABLE: false,
      id: 0,
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      formd: {},
      rules: {
        Status: [{ required: true, type: "number", message: " " }],
        OrderId: [{ required: true, type: "number", message: " " }],
        MarketerId: [{ required: true, type: "number", message: " " }],
        Deadline: [
          {
            validator: (rule, value, callback) => {
              this.formd.Deadline ? callback() : callback(new Error(" "));
            },
          },
        ],
      },
    };
  },
  methods: {
    open(id) {
      if (id) {
        this.ABLE = this.$able.map.BtmsCashCollUpd;
        this.id = id;
        this.req();
      } else {
        this.ABLE = this.$able.map.BtmsCashCollAdd;
      }
      this.flag = true;
    },
    close() {
      this.ABLE = false;
      this.flag = false;
      this.id = 0;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.formd = {};
      this.$refs.form.resetFields();
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsCashCollectionPlan/GetVo", { params: { Id: this.id } })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data;
          } else {
            this.failed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      let api,
        params = this.$util.copy(this.formd);
      if (this.id > 0) {
        api = "/Api/BtmsCashCollectionPlan/Update";
      } else {
        api = "/Api/BtmsCashCollectionPlan/Add";
      }
      if (this.formd.Deadline != null && typeof this.formd.Deadline == "object") {
        params.Deadline = this.formd.Deadline.format("yyyy-MM-dd");
      }
      if (this.formd.RevTime != null && typeof this.formd.RevTime == "object") {
        params.RevTime = this.formd.RevTime.format("yyyy-MM-dd hh:mm");
      }
      if (this.formd.NoticeTime != null && typeof this.formd.NoticeTime == "object") {
        params.NoticeTime = this.formd.NoticeTime.format("yyyy-MM-dd");
      }
      this.posting = true;
      this.$axios
        .post(api, params)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            if (this.id > 0) {
              this.$Message.success("保存成功");
              this.$emit("on-upd");
            } else {
              this.$Message.success("新增成功");
              this.$emit("on-add");
            }
            this.close();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // 选择委托单
    fastPickOrder() {
      this.formd.OrderId = this.$store.state.order.Id;
      this.$set(this.formd, "OrderCode", this.$store.state.order.Code);
    },
    toPickOrder() {
      if (this.formd.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.formd.OrderId, Code: this.formd.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.formd.OrderId = lst[0].Id;
        this.$set(this.formd, "OrderCode", lst[0].Code);
        if (lst[0].PartybMarketerId) {
          this.$set(this.formd, "OrderCode", lst[0].Code);
          this.$set(this.formd, "OrderCode", lst[0].Code);
          if (lst[0].PartybMarketerId) {
            this.$set(this.formd, "MarketerId", lst[0].PartybMarketerId);
            this.$set(this.formd, "MarketerName", lst[0].PartybMarketerName);
          } else {
            this.$set(this.formd, "MarketerId", "");
          }
        }
      } else {
        this.formd.OrderId = "";
        this.$set(this.formd, "OrderCode", "");
      }
    },
    unPickOrder() {
      this.formd.OrderId = "";
      this.$set(this.formd, "OrderCode", "");
    },
    // 选择人员
    toPickUser() {
      if (this.formd.MarketerId) {
        this.$refs.PickUser.open([
          { Id: this.formd.MarketerId, UserName: this.formd.MarketerName },
        ]);
      } else {
        this.$refs.PickUser.open();
      }
    },
    onPickUser(lst) {
      if (lst[0]) {
        this.formd.MarketerId = lst[0].Id;
        this.$set(this.formd, "MarketerName", lst[0].UserName);
      } else {
        this.formd.MarketerId = "";
        this.$set(this.formd, "MarketerName", "");
      }
    },
    unPickUser(i) {
      this.formd.MarketerId = "";
      this.$set(this.formd, "MarketerName", "");
    },
  },
};
</script>
<style lang="less">
.cash-collection-plan-drawer {
  .ivu-form-item {
    margin-bottom: 10px;
  }
  .sign-user {
    display: flex;
    border-bottom: 1px dashed #dcded2;
  }
  .sign-user > span:first-child {
    flex: 1;
    display: inline-block;
    margin-right: 10px;
  }
}
</style>